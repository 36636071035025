import { DateTime } from "luxon";

function getBeginningOfNextDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

function getCurrentYear() {
  return new Date().getFullYear();
}

function getDatesOfLastWeek() {
  const firstDay = getPreviousSunday();
  const lastDay = getBeginningOfNextDay(getPreviousSaturday());

  return { firstDay, lastDay };
}

function getPreviousDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
}

function getPreviousSaturday() {
  let previousSaturday = getPreviousSunday();
  previousSaturday.setDate(previousSaturday.getDate() + 6)
  return previousSaturday;
}

function getPreviousSunday() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7);
}

function getToday() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

function getTomorrow() {
  return getBeginningOfNextDay(new Date());
}

function getYesterday() {
  const now = new Date();
  return getPreviousDay(now);
}

function toDayAndMonth(date) {
  let pacificDate;

  if (typeof date === 'string')
    pacificDate = DateTime.fromISO(date, {zone: "America/Los_Angeles"}).toJSDate();
  else
    pacificDate = date;

  const options = {
    month: "short",
    day: "numeric",
  };
  return pacificDate.toLocaleDateString('en-us', options);
}

function toMonthAndYear(date) {
  let pacificDate;

  if (typeof date === 'string')
    pacificDate = DateTime.fromISO(date, {zone: "America/Los_Angeles"}).toJSDate();
  else
    pacificDate = date;

  const options = {
    month: "short",
    year: "numeric",
  };
  return pacificDate.toLocaleDateString('en-us', options);
}

function toFullDateString(date) {
  let pacificDate;

  if (typeof date === 'string')
    pacificDate = DateTime.fromISO(date, {zone: "America/Los_Angeles"}).toJSDate();
  else
    pacificDate = date;

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return pacificDate.toLocaleDateString('en-us', options);
}

function toISODate(date) {
  return DateTime.fromJSDate(date).toISO();
}

export {
  getBeginningOfNextDay,
  getCurrentYear,
  getDatesOfLastWeek,
  getPreviousDay,
  getPreviousSaturday,
  getPreviousSunday,
  getToday,
  getTomorrow,
  getYesterday,
  toDayAndMonth,
  toFullDateString,
  toISODate,
  toMonthAndYear
};
