import React, {useState, useEffect, useContext} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AuthContext from "../store/auth-context";
import API from "../ApiInterface";

function ModalView() {
    const [show, setShow] = useState(false);
    const authCtx = useContext(AuthContext);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const endTime = localStorage.getItem('expirationTime') ? localStorage.getItem('expirationTime') : (new Date().getTime() + 3600000);

    const currentTime = new Date().getTime();
    const adjustedExpirationTime = new Date(endTime).getTime();
    const remainingTime = adjustedExpirationTime - currentTime;

    useEffect(() => {
        setTimeout(handleShow, remainingTime - 300000);
    }, [remainingTime])

    const refreshTokenMeth = () => {
        setShow(false);
        API.refreshToken(authCtx.refreshToken).then(r => {
            const expirationTime = new Date((new Date().getTime() + (+r.expires_in * 1000)));
            <AuthContext renew={true}/>
            authCtx.login(r.access_token, r.refresh_token, expirationTime.toString(), true);
        })
    };

    const logoutUser = () => {
        setShow(false)
        authCtx.logout();
    }

    const contentView = <>
        <Button variant="primary" onClick={handleShow} hidden="hidden">
            Launch static backdrop modal
        </Button>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Do you need extra time?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your session is about to expire in 5mins. For more time, please click 'Extend' to continue your session.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={logoutUser}>
                    Logout
                </Button>
                <Button variant="danger" onClick={refreshTokenMeth}>Extend Session</Button>
            </Modal.Footer>
        </Modal>
    </>;

    return (
        contentView
    );
}

export default ModalView;