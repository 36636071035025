import AuthContext from "./store/auth-context";
import FanCast from './components/fanCast';
import Login from './components/login';
import NavBar from './components/navbar';
import PRPlus from "./components/prPlus";
import React, { useContext } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import './styles/App.css';

const App = () => {
  const authCtx = useContext(AuthContext);

  return (
      <div className="bg-white">
        {authCtx.isLoggedIn && (<NavBar />)}
        <Routes>
          <Route>
            {!authCtx.isLoggedIn && (<Route path="/" element={<Login/>} />)}
            {authCtx.isLoggedIn && (<Route path="/" element={ <Navigate to="/plus" /> } />)}
            {authCtx.isLoggedIn && (<Route path="/plus" element={<PRPlus />} />)}
            {authCtx.isLoggedIn && (<Route path="/fancast" element={<FanCast />} />)}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </div>
  );
}

export default App;
