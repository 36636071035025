import  React from 'react';

const BigNumbers = (props) => {
    let title = props.title;
    let value = props.value;

    return (
        <div>
            <div><h4 className="pb-0 text-dark fw-lighter" style={{fontFamily: 'Montserrat, sans-serif', fontSize: '14px'}}>{props.title}</h4></div>
            <h1 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '48px', marginTop: '-10px', marginBottom: '-10px'}} className=" text-danger fw-bolder">{props.value}</h1>
        </div>
    )
}

export default BigNumbers;