import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../store/auth-context";
import '../styles/navbar.css';

const Navbar = () => {
    const [prPlusActive, setPRPlusActive] = useState(false);
    const [fanCastActive, setFanCastActive] = useState(false);

    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;

    const logoutHandler = () => {
        authCtx.logout();
    }

    const setPRPlusRouteActive = () => {
        if (!prPlusActive) {
            setPRPlusActive(true);
            setFanCastActive(false)
        }
    };
    let prPlusRouteActiveStyle = prPlusActive ? "nav-link text-danger ms-3 me-3 active-item" : "nav-link text-dark ms-3 me-3";

    const setFanCastRouteActive = () => {
        if (!fanCastActive) {
            setFanCastActive(isActive2 => !isActive2);
            setPRPlusActive(false)
        }
    };
    let fanCastRouteActiveStyle = fanCastActive ? "nav-link text-danger ms-3 me-3 active-item" : "nav-link text-dark ms-3 me-3";

    useEffect(() => {
        switch (window.location.pathname) {
            case '/plus':
                setPRPlusRouteActive();
                break;
            case '/fancast':
                setFanCastRouteActive();
                break;
        }
    }, [window.location.pathname])

    return (
        <nav className="navbar navbar-expand-lg bg-white">
            <div className="container-fluid">
                <img
                    src="https://www.nicepng.com/png/full/840-8400920_pocket-radar.png"
                    alt="" height="30"/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav text-light">
                        {isLoggedIn &&
                            <li className="nav-item fs-5">
                                <Link style={{color: 'red'}} to="/plus" onClick={setPRPlusRouteActive} className={prPlusRouteActiveStyle}>Pocket Radar PLUS</Link>
                            </li>}
                        {isLoggedIn &&
                            <li className="nav-item fs-5">
                                <Link to="/fancast" onClick={setFanCastRouteActive} className={fanCastRouteActiveStyle}>FanCast</Link>
                            </li>}
                        {isLoggedIn &&
                            <li className="nav-item fs-5 pe-5">
                                <Link onClick={logoutHandler} to="/"
                                      className="nav-link text-dark ps-5">Logout</Link>
                            </li>}
                    </ul>
                    <div className="text-end">
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;