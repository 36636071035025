import { toISODate } from "./dates";

let appURL = "http://localhost:8008/api/";
let identityURL = "http://localhost:8010/identity/";
let oauthURL = 'http://localhost:8010/oauth/token';
let measurementURL = "http://localhost:8009/measurement/";
let sportsURL = "http://localhost:8011/sports/";
let metricURL = 'http://localhost:8008/';

if (process.env.REACT_APP_ENV === "staging") {
    appURL = "https://app.api.staging.pocketradar-services.com/api/";
    identityURL = "https://app.api.staging.pocketradar-services.com/identity/";
    oauthURL = "https://app.api.staging.pocketradar-services.com/oauth/token";
    measurementURL = "https://app.api.staging.pocketradar-services.com/measurement/";
    sportsURL = "https://app.api.staging.pocketradar-services.com/sports/";
    metricURL = 'https://app.api.staging.pocketradar-services.com/';
} else if (process.env.REACT_APP_ENV === "production") {
    appURL = "https://api.pocketradar.com/api/";
    identityURL = "https://api.pocketradar.com/identity/";
    oauthURL = "https://api.pocketradar.com/oauth/token";
    measurementURL = "https://api.pocketradar.com/measurement/";
    sportsURL = "https://api.pocketradar.com/sports/";
    metricURL = 'https://api.pocketradar.com/';
}

class ApiInterface {
    static async login(email, password) {
        return fetch(oauthURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'username': email,
                'password': password,
                'grant_type': 'password'
            })
        }).then(response => {
            return response.json()
        })
    }

    static async refreshToken(refreshToken) {
        return fetch(oauthURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'refresh_token': refreshToken,
                'grant_type': 'refresh_token'
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getPRPlusPurchases(startDate, endDate, token) {
        return await fetch(
            metricURL + "metrics/v2/metrics/purchases" +
            "?product=pocketradarplus" +
            "&startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getPRPlusPurchasesByDay(startDate, endDate, token) {
        return await fetch(
          metricURL + "metrics/v2/metrics/purchases/subscriptions/byDay" +
          "?product=pocketradarplus" +
          "&startDate=" + toISODate(startDate) +
          "&endDate=" + toISODate(endDate),
          {
              headers: {
                  'Authorization': "Bearer " + token
              }
          })
          .then((response) => response.json())
    }

    static async getEventPurchases(startDate, endDate, token) {
        return await fetch(
            metricURL + "metrics/v2/metrics/events/purchases?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getPRConnectPartnerBreakdown(startDate, endDate, token) {
        return await fetch(
            metricURL + "metrics/v2/metrics/purchases/partner?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getOrganizationEventPurchases(startDate, endDate, token) {
        return await fetch(
            metricURL + "metrics/v2/metrics/events/purchases/byOrg?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getPartnerLogos(token) {
        return await fetch(
            identityURL + 'v2/partners/logos',
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getFancastOrgDayPassPurchases(startDate, endDate, token) {
        return await fetch(
            metricURL + "metrics/v2/metrics/events/purchases/daypass?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getFancastOrgFullAccessPassPurchases(startDate, endDate, token) {
        return await fetch(
          metricURL + "metrics/v2/metrics/events/purchases/fullaccesspass?" +
          "startDate=" + toISODate(startDate) +
          "&endDate=" + toISODate(endDate),
          {
              headers: {
                  'Authorization': "Bearer " + token
              }
          })
          .then((response) => response.json())
    }
    static async getUserStreamData(startDate, endDate, token) {
        return await fetch(
            metricURL  + "metrics/v2/metrics/events/userStreams?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }

    static async getGameStreamData(startDate, endDate, token) {
        return await fetch(
            metricURL  + "metrics/v2/metrics/events/gameStreams?" +
            "startDate=" + toISODate(startDate) +
            "&endDate=" + toISODate(endDate),
            {
                headers: {
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => response.json())
    }
}

export default ApiInterface;