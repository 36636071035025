import React from 'react'
import { Bar } from 'react-chartjs-2'
import {CategoryScale} from 'chart.js';
Chart.register(CategoryScale);
import Chart from 'chart.js/auto';

function BarChartStack({org1  = '', org2 = '', org3 = '', org4 = '', data1 = [], data2 = [], data3= [], data4 = [], labels }) {
    let showSubtitle = true;

    let datasets = [];
    if (data1.find(v => v > 0)) {
        showSubtitle = false;
        datasets.push(                        {
            label: org1,
            data: data1,
            backgroundColor: [
                '#E31937',
            ],
            borderColor: [
            ],
        })
    }

    if (data2.find(v => v > 0)) {
        datasets.push(                        {
            label: org2,
            data: data2,
            backgroundColor: [
                '#0BAE4C',
            ],
            borderColor: [
                '#0BAE4C',
            ],
        })
    }

    if (data3.find(v => v > 0)) {
        datasets.push(                        {
            label: org3,
            data: data3,
            backgroundColor: [
                '#318AE9',
            ],
            borderColor: [
                '#0BAE4C',
            ],
        })
    }

    if (data4.find(v => v > 0)) {
        datasets.push(                        {
            label: org4,
            data: data4,
            backgroundColor: [
                '#808080',
            ],
            borderColor: [
            ],
        })
    }

    return (
        <div>
            <Bar
                data={{
                    labels: labels,
                    datasets: datasets,
                }}
                height={325}
                options={{
                    layout: {
                        padding: 0
                    },
                    backgroundColor: 'orange',
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: {
                                stacked: true,
                            grid: {
                                display: false,
                                color: '#000',
                                z: -9
                            },
                            ticks: {
                                color: '#888',
                                font: {
                                    size: 22,
                                },

                            },
                        },
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#000',
                                font: {
                                    size: 16
                                }
                            }
                        }
                    },
                    legend: {
                        labels: {
                            font: {
                                size: 18,
                                color: 'green'
                            },
                        },
                    },
                    plugins: {
                        scales: {
                        },
                        legend: {
                            align: 'start',
                            display: false,
                            labels: {
                                font: {
                                    size: 18
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                size: 20
                            },
                            backgroundColor: '#eee',
                            bodyColor: '#000',
                            titleColor: 'black',
                            titleFont: {
                                weight: 'bold',
                                size: 18
                            },
                            mode: 'index',
                            intersect: true
                        },
                        title: {
                            display: true,
                            align: 'start',
                            text: '',
                            color: '#4c8ff5',
                            font: {
                                size: 18
                            }
                        },
                        subtitle : {
                            display: showSubtitle,
                            align: 'center',
                            text: 'No Data for Interval',
                            font: {
                                size: 22,
                            },
                            color: '#555'
                        },
                    }
                }}
            />
        </div>
    )
}

export default BarChartStack;