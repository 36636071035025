import React from 'react';

export const DataDisplay = function({title, logoUrl, dataValue, valueColor, altText, showLogo = true }) {
    const custStyle = {height: 'auto', border: 'none', outline: 'none'};
    let logoContent;

    if (showLogo) {
        if (logoUrl) {
            logoContent = <>
                <div className="col-3 pt-3 pb-0 mx-0 align-content-end bg-white ">
                    <img
                      src={logoUrl}
                      height="32px" width="32px" style={{ objectFit: 'contain' }} alt={altText} />
                </div>
            </>;
        } else {
            logoContent = <>
                <div className="col-1 pt-3 pb-0 mx-0 align-content-end bg-white ">
                    <div style={{height: "32px"}}>&nbsp;</div>
                </div>
            </>
        }
    } else {
        logoContent = null
    }

    return (
        <div style={custStyle} className="rounded-4 ">
            <div className="card text-center" style={{border: 'none'}}>
                <div className="row">
                    {logoContent}
                    <div className="col-sm-6 card-header text-dark bg-white d-flex align-items-center text-nowrap"
                         style={{fontSize: '16px', fontFamily: 'Montserrat, sans-serif'}}>{title}
                    </div>
                </div>
                <div className="card-body d-flex">
                    <h1 style={{fontFamily: 'Montserrat, sans-serif', fontSize: '80px', marginLeft: '1px', color: valueColor }}
                        className="card-title bg-white mx-0 fw-bolder">{dataValue}</h1>
                </div>
            </div>
        </div>
    )
}
